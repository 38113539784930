<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Pojects Issue chart" />
    <a-space :size="20">
      <span>
        Current Project
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item
                v-for="item in list"
                :key="item.id"
                @click="current = item"
              >
                {{ item.name }}({{ item.id }})
              </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            {{ current.name }}
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </span>
    </a-space>

    <div class="mt-30">
      <IssuesChart />
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";

import { DownOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject } from "vue";
import IssuesChart from "@/components/chart/IssuesChart";

export default {
  components: {
    IssuesChart,
    DownOutlined,
  },

  setup() {
    const { $msg, gql, $filters } = inject("$");

    const v = reactive({});

    const QL = gql`
      query {
        projects {
          id
          name
        }
      }
    `;

    const { result, onResult, loading, onError } = useQuery(QL, null, {
      fetchPolicy: "network-only",
    });

    const list = useResult(result, null, (data) => data.projects);
    const current = ref({});

    onResult((res) => {
      if (!res.data) return;
      current.value = res.data.projects[0] ? res.data.projects[0] : {};
    });

    onError((error) => {
      $msg.error("Network Error");
    });

    return {
      ...toRefs(v),
      list,
      listloading: loading,
      current,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
</style>
